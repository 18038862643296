import { Tooltip } from 'antd'
import { useTypedTranslation } from 'src/common/utils'
import { FieldTranslation } from 'src/generated/graphql/types'

type TranslatedValueProps = {
  fields: FieldTranslation[] | undefined
}
export function TranslatedValue({ fields }: TranslatedValueProps) {
  const { t, getTranslatedValue } = useTypedTranslation()
  const { value, isCurrentLanguage } = getTranslatedValue(fields)
  if (isCurrentLanguage) return value
  if (!value) return null
  return (
    <span className="inline-flex gap-1 italic items-center">
      {value}
      <Tooltip title={t('common.translation_required')}>
        {/* z-50 allows tootlip to be displayed even in the navigation menu */}
        <span className={'z-50 text-color-green-grey-30'}>*</span>
      </Tooltip>
    </span>
  )
}
