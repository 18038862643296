import { create } from 'zustand'
import { ContributingEntityDetailsFormProps } from 'src/organization/_components/ContributingEntityDetailsForm'
import { EditableContributingEntity } from 'src/provider'

type OpenModalParams =
  | { item: EditableContributingEntity; parentNode?: undefined }
  | { parentNode: HandleModalType['contributingEntityParent']; item?: undefined }

type HandleModalType = {
  isContributingEntityFormModalVisible: boolean
  contributingEntityToUpdate?: EditableContributingEntity
  contributingEntityParent?: ContributingEntityDetailsFormProps['parentNode']
  openContributingEntityFormModal: (params: OpenModalParams) => void
  closeContributingEntityFormModal: () => void
}

export const useContributingEntityFormModal = create<HandleModalType>(set => ({
  isContributingEntityFormModalVisible: false,
  closeContributingEntityFormModal: () =>
    set({
      isContributingEntityFormModalVisible: false,
      contributingEntityToUpdate: undefined,
      contributingEntityParent: undefined
    }),
  openContributingEntityFormModal: ({ item, parentNode }: OpenModalParams) =>
    set({
      isContributingEntityFormModalVisible: true,
      contributingEntityToUpdate: item,
      contributingEntityParent: parentNode
    })
}))
