import { create } from 'zustand'
import { OrganizationNodeDetailsFormProps } from 'src/organization/_components/OrganizationNodeDetailsForm'
import { EditableOrganizationNode } from 'src/provider'

type OpenModalParams =
  | { item: EditableOrganizationNode; parentNode?: undefined }
  | { parentNode: HandleModalType['organizationNodeParent']; item?: undefined }

type HandleModalType = {
  isOrganizationNodeFormModalVisible: boolean
  organizationNodeToUpdate?: EditableOrganizationNode
  organizationNodeParent?: OrganizationNodeDetailsFormProps['parentNode']
  openOrganizationNodeFormModal: (params: OpenModalParams) => void
  closeOrganizationNodeFormModal: () => void
}

export const useOrganizationNodeFormModal = create<HandleModalType>(set => ({
  isOrganizationNodeFormModalVisible: false,
  closeOrganizationNodeFormModal: () =>
    set({
      isOrganizationNodeFormModalVisible: false,
      organizationNodeToUpdate: undefined,
      organizationNodeParent: undefined
    }),
  openOrganizationNodeFormModal: ({ item, parentNode }: OpenModalParams) =>
    set({
      isOrganizationNodeFormModalVisible: true,
      organizationNodeToUpdate: item,
      organizationNodeParent: parentNode
    })
}))
